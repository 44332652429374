.smsCounter {
  float: inline-end;
  margin-top: 10px;
}
.pl-10 {
  padding-left: 10px;
}

.rdtPicker {
  background-color: var(--bs-gray-200) !important;
  border-color: var(--bs-gray-200) !important;
  color: var(--bs-gray-700) !important;
  border-radius: 5px;
}

.float-right {
  float: right;
}

.item-portefolio-title {
  position: absolute;
  height: 46px;
  width: calc(100% - 108px);
  opacity: 0.7;
  border-radius: 5px;
  margin-left: 5px;
  padding: 12px;
}
.buttonRemove {
  position: absolute;
  top: 0;
  right: 0;
}
.buttonEdit {
  position: absolute;
  top: 0;
  right: 50px;
}
.overflow-scroll {
  overflow: scroll;
}
.p-relative {
  position: relative;
}
.fit-cover {
  object-fit: cover;
}
.fit-contain {
  object-fit: contain;
}
.padding-top-10 {
  padding-top: 10px !important;
}
.padding-top-15 {
  padding-top: 15px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.padding-top-40 {
  padding-top: 40px !important;
}

.padding-top-50 {
  padding-top: 50px !important;
}

.padding-top-75 {
  padding-top: 75px !important;
}
.padding-top-120 {
  padding-top: 120px !important;
}
.padding-left-20 {
  padding-left: 20px;
}
.padding-bottom-0 {
  padding-bottom: 0 !important;
}
.padding-bottom-1 {
  padding-bottom: 1px !important;
}
.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.padding-bottom-30 {
  padding-bottom: 30px !important;
}

.padding-bottom-40 {
  padding-bottom: 40px !important;
}

.padding-bottom-50 {
  padding-bottom: 50px !important;
}
.padding-top-blog {
  padding-top: 70px !important;
}
.no-padding {
  padding: 0 !important;
}
.padding-0-5 {
  padding: 0 5px !important;
}
.padding-0-15 {
  padding: 0 15px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-15 {
  padding: 15px !important;
}

.padding-20 {
  padding: 20px !important;
}

.padding-30 {
  padding: 30px !important;
}

.padding-40 {
  padding: 40px !important;
}

.padding-50 {
  padding: 50px !important;
}

.padding-200 {
  padding: 200px !important;
}
.padding-top-0 {
  padding-top: 0 !important;
}
.margin-20-0 {
  margin: 20px 0 !important;
}
.no-margin-left {
  margin-left: 0 !important;
}
.no-margin-top {
  margin-top: 0 !important;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}
.margin-top-70 {
  margin-top: 70px !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}
.no-margin-left {
  margin-left: 0 !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-left-30 {
  margin-left: 30px !important;
}

.margin-left-40 {
  margin-left: 40px !important;
}

.margin-left-50 {
  margin-left: 50px !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-right-30 {
  margin-right: 30px !important;
}

.margin-right-40 {
  margin-right: 40px !important;
}

.margin-right-50 {
  margin-right: 50px !important;
}
.no-margin {
  margin: 0 !important;
}

.margin-10 {
  margin: 10px !important;
}

.margin-20 {
  margin: 20px !important;
}

.margin-30 {
  margin: 30px !important;
}

.margin-40 {
  margin: 40px !important;
}

.margin-50 {
  margin: 50px !important;
}

.cursor-move {
  cursor: move;
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.color-danger {
  color: var(--bs-text-danger) !important;
}

.menu-column {
  max-height: calc(100vh - 150px);
}
.mhbodyClientForm {
  min-height: calc(100vh - 300px);
}
.modalBodyHeight {
  max-height: calc(100vh - 300px);
}
.modalBodySegmentsFilter {
  max-height: calc(100vh - 400px);
  overflow: auto;
}
.cardStatisticsBodyHeight {
  max-height: calc(100vh - 180px);
}
.modalBodyTabsHeight {
  height: calc(100vh - 350px);
}
.timeline-item-title.active {
  background: rgba(255, 255, 255, 0) !important;
}
.timeline-card-content {
  padding: 10px;
}
.card-description {
  max-height: fit-content !important;
  height: fit-content;
}
div:has(> .itemTimeLineContent) {
  width: 100%;
}

div:has(> div > ul.timeline-controls) {
  display: none;
}

.w-color-sketch {
  background: transparent !important;
}

.timeline-label.clientHistory:before {
  left: 50% !important;
}
.timeline-label.clientHistory .timeline-label:before {
  content: none;
}

.timeline-label.clientHistory .timeline-label {
  width: 50% !important;
}

.timeline-label.LabelRight {
  text-align: right;
  padding: 0 20px;
}

.timeline-label.LabelLeft {
  padding: 0 20px;
}
.timeline-label.clientHistory .timeline-label.LabelLeft {
  width: 49.3% !important;
}

.timeline-margin-top-negative {
  margin-top: -30px;
}

.filterInvert {
  filter: invert(1);
}

.btnNavBar {
  position: absolute;
  right: 28px;
  top: 7px;
}

.searchResult {
  max-height: 200px;
  overflow: auto;
  position: absolute;
  width: calc(100% - 70px);
}
.input-group-text {
  border-radius: 0;
  border-color: var(--bs-gray-100);
  cursor: pointer;
}
.heightListClients {
  overflow: auto;
  max-height: calc(100vh - 400px);
}

.tableFixHead {
  overflow: auto;
  height: 100px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #eee;
}

table thead tr th:not(:nth-child(1)){
  text-align: center;
}

.listOrdered {
  list-style-type: none;
  padding-left: 0;
}
.listOrdered li {
  padding: 10px;
  border-bottom: 1px solid var(--bs-border-color);
  font-size: 15px;
  border-radius: var(--bs-card-inner-border-radius) 
}

.border-radius-none-right{
      border-radius: var(--bs-btn-border-radius) 0 0 var(--bs-btn-border-radius) !important;
}
.border-radius-none-left{
      border-radius: 0 var(--bs-btn-border-radius) var(--bs-btn-border-radius) 0 !important;
}

.border-0{
  border: 0px !important
}
.border-light{
      border: 1px solid var(--bs-light);
}

.max-height-70vh{
  max-height: 70vh;
}
.width-180{
  width: 180px;
}

.text-capitalize{
  text-transform: capitalize;
}

.tableFixHead          { overflow: auto; height: 100px; }
.tableFixHead thead th { position: sticky; top: -26px; z-index: 1; background-color: var(--bs-body-bg); }


.tooltipGraphHeader{
  font-size: 15px;
  font-weight: 700;
  padding: 10px 25px !important;
  min-height: 0px !important;
}
.tooltipGrapBody{
  padding: 10px 25px !important;
}

.tooltipGrapBody ul {
  list-style-type: none;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
}
.tooltipGrapBody li {
  padding: 5px 0px;
  font-size: 12px;
}

.modal-header .btn.btn-icon{
  position: absolute;
  right: 15px;
}


.border-radius-10{
  border-radius: 10px;
}

.btn-download-custom{
  padding: 10px !important;
  font-size: 14px !important;
  border-radius: 5px;
  margin-right: 20px !important;
}

.fixed-width-drag-icon{
    -ms-flex: 0 0 25px;
    flex: 0 0 25px;
    cursor: move;
} 
.fixed-width-remove-button{
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
} 

.box-shadow-drag{
  box-shadow: 2px 5px 50px -20px var(--bs-gray-400);
}

.apexcharts-tooltip {
  bottom: 0 !important;
  top: auto !important;
}